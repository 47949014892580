
import {namespace} from 'vuex-class';
import {Component, Prop} from 'vue-property-decorator';
import {AUTH_STORE_NAME} from '@/store/auth.store';
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixins';
import {mixins} from 'vue-class-component';

const AuthStore = namespace(AUTH_STORE_NAME);

@Component
export default class TwoFactorLoginComponent extends mixins(ErrorMessageHandlerMixin) {
  @Prop({required: true})
  private onLoginPressed!: (code: string) => Promise<void>;

  @Prop({required: true})
  private onBackPressed!: () => void;

  private code = '';
  private isLoading = false;

  /**
   * is called when user taps on login button, tries to login the user
   * @private
   */
  private async doLogin() {
    try {
      this.isLoading = true;
      await this.onLoginPressed(this.code);
    } finally {
      this.isLoading = false;
    }
  }
}
